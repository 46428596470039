import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <>
       <section className="about-area ptb-120 bg-image" id="about1">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                        <div
                className="about-content"
                style={{ fontSize: 18, textAlign: "center" }}
              >
                <h3>Join us at JW Marriott Prestige Golfshire Resort & Spa</h3>
                <br />
                <p style={{ fontSize: 18 }}>
                  A beautiful amalgamation of tranquility and convenience, the
                  resort proves to be the best getaway to mix business and
                  leisure.
                </p>
                <button
                  to="/pricing-1"
                  className="btn btn-primary"
                  onClick={() => {
                    window.open(
                      "https://www.marriott.com/hotels/travel/blrnh-jw-marriott-bengaluru-prestige-golfshire-resort-and-spa/"
                    );
                  }}
                >
                  MORE ABOUT THE RESORT  
                </button>
              </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src="./jw2.jpg" className="about-img1" alt="about" />

                                <img src="./jw1.jpg" className="about-img2" alt="about" />

                                {/* <img src={require("../../assets/images/shapes/5.png")} className="shape-img" alt="about" /> */}

                                {/* <LaxButton buttonText="Explore More About" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       
        <footer className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-footer-widget" style={{color:"#cc0000"}}>
                  <h3>Venue</h3>
                  <span style={{color:"#cc0000"}}>
                    <i style={{color:"#cc0000"}} className="icofont-calendar"></i> 16th June to 18th June,
                    2022
                  </span>

                  <p className="location" style={{color:"#cc0000"}}>
                    <i className="icofont-google-map"></i> JW Marriott Bengaluru
                    Prestige Golfshire
                  </p>

                  <a to="/contact" style={{color:"#cc0000"}} className="contact-authority">
                    <i className="icofont-phone"></i> Contact Us
                    <br />
                    <a href="mailto:neha.naik@hitachivantara.com">
                      neha.naik@hitachivantara.com
                    </a>
                    <br />
                    <a href="mailto:advaith.rk@framez.sg">
                      advaith.rk@framez.sg
                    </a>
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="single-footer-widget">
                  <h3>Social Connection</h3>
                  {/* <p>Don't miss Link thing! Receive daily news You should connect social area for Any Proper Updates Anytime.</p> */}

                  <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/HitachiVantara"
                        className="facebook"
                        target="_blank"
                      >
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/HitachiVantara"
                        className="twitter"
                        target="_blank"
                      >
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/hitachi-vantara/"
                        className="linkedin"
                        target="_blank"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/c/HitachiVantara"
                        className="youtube"
                        target="_blank"
                      >
                        <i className="icofont-youtube-play"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="copyright-area">
                  <div className="logo">
                    <a
                      href="https://www.hitachivantara.com/"
                      target="_blank"
                      to="/"
                    >
                      <img
                        src={require("../../assets/images/hitachi4.png")}
                        width={200}
                        alt="logo"
                      />
                    </a>
                  </div>
                  {/* <ul>
                                    <li><Link to="/blog-1">Blog</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="#">Ticket</Link></li>
                                    <li><Link to="#">Venue</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                </ul> */}
                  <p style={{color:"#cc0000"}}>
                    Copyright <i className="icofont-copyright"></i> 2022 Framez.
                    All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
