// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/database'

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  console.log = () => {};

}
const config = {
  apiKey: "AIzaSyCfrW9wYzZRicWSCXKkxJ3jlrSEGX7rk14",
  authDomain: "hvpartnersummit2022.firebaseapp.com",
  databaseURL: "https://hvpartnersummit2022-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hvpartnersummit2022",
  storageBucket: "hvpartnersummit2022.appspot.com",
  messagingSenderId: "463285321682",
  appId: "1:463285321682:web:fb6bcba031ce010654520b",
  measurementId: "G-YM2VK5L2CH"
};
firebase.initializeApp(config)
firebase.analytics();