import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import HomeDefault from "./components/pages/HomeDefault";
import Login from "./components/pages/Login";
import NotFound from "./components/pages/NotFound";
import Signup from "./components/pages/Signup";
import { history } from "./history";
import firebase from "firebase";
import Preloader from "./components/Shared/Preloader";
import ComingSoon from "./components/pages/ComingSoon";
// import {browserHistory} from 'react-router'

// Conditionally render Navigation
const renderNavigation = () => {
  if (
    !(
      window.location.pathname === "/login" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/coming-soon" ||
      window.location.pathname === "/error-404"
    )
  ) {
    return <Navigation />;
  }
};
class AppRouter extends React.Component {
  // const AppRouter = () => {
  state = { loading: true };
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("Logged in");

        history.push("/");
        this.setState({
          loading: false,
        });
      } else {
        console.log("Not logged in");
        history.push("/signup");
        this.setState({
          loading: false,
        });
      }
    });
    // this.demoAsyncCall().then(() => this.setState({ loading: false }));
  }
  render() {
    if (this.state.loading) {
      return <Preloader />;
    }
    return (
      <Router history={history}>
        {/* {renderNavigation()} */}
        <Switch>
          <Route path="/" exact component={HomeDefault} />
          {/* <Route path="/home-two" exact component={HomeTwo} />
                <Route path="/home-three" exact component={HomeThree} />
                <Route path="/home-four" exact component={HomeFour} />
                <Route path="/home-five" exact component={HomeFive} />
                <Route path="/about-1" exact component={AboutOne} />
                <Route path="/about-2" exact component={AboutTwo} />
                <Route path="/about-3" exact component={AboutThree} />
                <Route path="/pricing-1" exact component={PricingOne} />
                <Route path="/pricing-2" exact component={PricingTwo} />
                <Route path="/speakers-1" exact component={SpeakersOne} />
                <Route path="/speakers-2" exact component={SpeakersTwo} />
                <Route path="/speakers-3" exact component={SpeakersThree} />
                <Route path="/schedule-1" exact component={ScheduleOne} />
                <Route path="/schedule-2" exact component={ScheduleTwo} />
                <Route path="/schedule-3" exact component={ScheduleThree} />
                <Route path="/schedule-4" exact component={ScheduleFour} /> */}
          <Route path="/login" exact component={Login} />
          <Route path="/signup" exact component={Signup} />{" "}
          <Route path="/coming-soon" exact component={ComingSoon} />
          {/* <Route path="/coming-soon" exact component={ComingSoon} />
                <Route path="/faq" exact component={Faq} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/blog-1" exact component={BlogOne} />
                <Route path="/blog-2" exact component={BlogTwo} />
                <Route path="/blog-3" exact component={BlogThree} />
                <Route path="/blog-4" exact component={BlogFour} />
                <Route path="/single-blog" exact component={BlogDetails} />
                <Route path="/sponsors" exact component={Sponsor} />*/}
          <Route path="/error-404" exact component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
