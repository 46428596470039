import React from "react";
import lax from "lax.js";
import { Link } from "react-router-dom";
import LaxButton from "../Shared/LaxButton";

class About extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section className="about-area ptb-120 bg-image" id="about">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                {/* <span>Join The Event</span> */}
                <h3>Join us at the India Partner Summit – 2022 </h3>
                <p>
                  {" "}
                  After almost two years of remote or hybrid events, we are excited to be back, in person, for our Hitachi Vantara India Partner Summit- 2022. This is an invitation only, 3-day residential summit exclusively designed for select partner leadership in the country. The travel and accommodation for the summit is taken care by Hitachi Vantara.
                </p>
                <p>Date: 16-18 June 2022</p> 
                <p>
                  Venue: JW MARRIOTT BENGALURU PRESTIGE GOLFSHIRE RESORT & SPA
                </p>
              {/*   <p>
                  At Hitachi Vantara, we give our partners the front-row access
                  to emerging technologies like cloud, object storage, Internet
                  of Things (IoT) and much more.
                </p>
                <p>
                  Our offerings help customers adapt to the needs of workloads
                  of the future with a modernized edge-to-core-to-cloud
                  infrastructure that delivers agility, efficiency, and
                  resiliency. Take an integrated, strategic approach to
                  application modernization, data modernization and
                  infrastructure modernization. Work from this digital
                  foundation to innovate, evolve and deliver exceptional
                  experiences for our customers.
                </p>
                <p>
                  As our valued partner, you will have the chance to expand your
                  value by leveraging the Hitachi Vantara offerings.
                </p>
                {/* <p>After almost two years of remote or hybrid  events, we are excited to be back, in person, for our pan India partner event. Hitachi India Partner Summit is a 3-day event designed exclusively for Hitachi Vantara partners. We look forward to having all our partners across the nation under one roof. </p> */}

                <p>The summit offers an opportunity for our partners to engage with the Hitachi Vantara leadership team, hear our latest partner programs and technology updates, network with peers, while you experience the warm and welcoming Bengaluru resort with attractions such as Nandi Hills and others
Come join us to become a part of this unique experience as we gather to celebrate success.
 </p>
                {/* <p>Let us gear up with the latest developments and continue to successfully help customers realize the power of data!</p> */}
                {/* <div className="signature">
                                    <img src={require("../../assets/images/signature.png")} alt="signature" />
                                </div> */}

                {/* <Link to="/about-3" className="btn btn-primary">
                                    Read More 
                                    <i className="icofont-double-right"></i>
                                </Link> */}

                {/* <Link to="#" className="btn btn-secondary">Register</Link> */}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image">
                <img
                  src={require("../../assets/images/Hitachi -Post 750x500px 02-min.jpg")}
                  className="about-img1"
                  alt="about"
                />

                <img
                  src={require("../../assets/images/Hitachi -Post 309x424px 02-min.jpg")}
                  className="about-img2"
                  alt="about"
                />

                <img
                  src={require("../../assets/images/shapes/5.png")}
                  className="shape-img"
                  alt="about"
                />

                {/* <LaxButton buttonText="Explore More About" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
