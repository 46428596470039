import firebase from "firebase";
import React from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import logo from "../../assets/images/hitachi.png";
import { Spinner } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import SweetAlert from "react-bootstrap-sweetalert";

// import "flatpickr/dist/themes/light.css";
import "flatpickr/dist/themes/airbnb.css";

import {
  Label,
  Button,
  FormGroup,
  Form,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
class Navigation extends React.Component {
  state = {
    modal2: false,
    successAlert: false,
    collapsed: true,
    isOpen: false,
    modal: false,
    isloading: false,
    form: false,
    errorAlert: false,
    owndate: null,
    depdate: null,
    owncity: null,
    depcity: null,
    seatpref: null,
    mealpref: null,
    roomtype: null,
    accommeal: null,
    foodalergy: "",
    otherremarks: "",
    golf: null,
    trekslot: null,
    tshirt: null,
    errormsg: "",
    category: null,
  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
        window.history.pushState("", document.title, window.location.pathname);
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  componentDidUpdate(nextProps) {
    if (this.props.match.path !== nextProps.match.path) {
      // this.onRouteChanged();
      console.log("OK");
    }
  }
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  toggleModal2 = () => {
    this.setState((prevState) => ({
      modal2: !prevState.modal2,
    }));
  };
  onRouteChanged = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleRegister = (e) => {
    e.preventDefault();
    console.log(this.state);

    if (this.state.owndate === null&& this.state.category === "Partner") {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Onward Date.",
      });
    } else if (this.state.depdate === null&& this.state.category === "Partner") {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Return Date.",
      });
    } else if (this.state.owncity === null&& this.state.category === "Partner") {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Onward City.",
      });
    } else if (this.state.depcity === null&& this.state.category === "Partner") {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Return City.",
      });
    } else if (this.state.roomtype === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Accomodation Room Type.",
      });
    } else if (this.state.accommeal === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Accomodation Meal Type.",
      });
    } else if (this.state.tshirt === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select T-shirt Size.",
      });
    } else {
      console.log("Allow register");
      this.setState({
        infoloading: true,
      });
      var update = {};
      update["form"] = true;
      update["owndate"] = this.state.owndate ? this.state.owndate[0] : null;
      update["depdate"] = this.state.depdate ? this.state.depdate[0] : null;
      update["owncity"] = this.state.owncity ? this.state.owncity.value : null;
      update["depcity"] = this.state.depcity ? this.state.depcity.value : null;
      update["seatpref"] = this.state.seatpref
        ? this.state.seatpref.value
        : null;
      update["mealpref"] = this.state.mealpref
        ? this.state.mealpref.value
        : null;
      update["roomtype"] = this.state.roomtype
        ? this.state.roomtype.value
        : null;
      update["accommeal"] = this.state.accommeal
        ? this.state.accommeal.value
        : null;
      update["foodalergy"] = this.state.foodalergy;
      update["otherremarks"] = this.state.otherremarks;
      update["golf"] = this.state.golf ? this.state.golf.value : null;
      // update["trekslot"] = this.state.trekslot
      //   ? this.state.trekslot.value
      //   : null;
      update["tshirt"] = this.state.tshirt ? this.state.tshirt.value : null;
      console.log(update);
      firebase
        .database()
        .ref("pub_user/" + firebase.auth().currentUser.uid)
        .update(update)
        .then(() => {
          console.log("data updated");
          this.setState({
            form: true,
            successAlert: true,
            infoloading: false,
            modal: false,
          });
        })
        .catch((err) => {
          console.log(err);
          alert("There was an error");
          this.setState({
            form: true,
            infoloading: false,
            modal: false,
          });
        });
    }
  };
  render() {
    const { collapsed } = this.state;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
    return (
      <header id="header" className="header-area">
        <SweetAlert
          error
          title="Error"
          show={this.state.errorAlert}
          onConfirm={() => this.handleAlert("errorAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            {this.state.errormsg}
          </p>
        </SweetAlert>
        <SweetAlert
          success
          title="Success"
          show={this.state.successAlert}
          onConfirm={() => this.handleAlert("successAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            Thank you for submitting the details, team will reach out to you for
            further information and bookings.
          </p>
        </SweetAlert>
        <Modal isOpen={this.state.modal2} toggle={this.toggleModal2}>
          <ModalHeader toggle={this.toggleModal2}>Size Chart</ModalHeader>
          <ModalBody>
            <img src="/tshirt.jpg" width={"100%"} />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>BOOK YOUR SERVICE</ModalHeader>
          <ModalBody>
            {this.state.form ? (
              <>You have already submitted your details.</>
            ) : (
              <>
                <Form onSubmit={this.handleRegister}>
                  <>
                    {this.state.category === "Partner" ? (
                      <>
                        <h5>Air Ticket</h5>
                        <h7>Onward City & Date*</h7>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "40%", margin: "5%" }}>
                            <FormGroup className="form-label-group">
                              <Select
                                required
                                className="React"
                                classNamePrefix="select"
                                value={this.state.owncity}
                                onChange={(a) => {
                                  this.setState({
                                    owncity: a,
                                  });
                                }}
                                placeholder="Onward City"
                                options={[
                                  {
                                    value: "Mumbai, Maharashtra",
                                    label: "Mumbai, Maharashtra",
                                  },
                                  {
                                    value: "Bangalore, Karnataka",
                                    label: "Bangalore, Karnataka",
                                  },
                                  {
                                    value: "Chennai, Tamil Nadu",
                                    label: "Chennai, Tamil Nadu",
                                  },
                                  {
                                    value: "Kolkata, West Bengal",
                                    label: "Kolkata, West Bengal",
                                  },
                                  {
                                    value: "Lucknow, Uttar Pradesh",
                                    label: "Lucknow, Uttar Pradesh",
                                  },
                                  {
                                    value: "Amritsar, Punjab",
                                    label: "Amritsar, Punjab",
                                  },
                                  {
                                    value: "Visakhapatnam, Andhra Pradesh",
                                    label: "Visakhapatnam, Andhra Pradesh",
                                  },
                                  {
                                    value: "Kannur, Kerala",
                                    label: "Kannur, Kerala",
                                  },
                                  {
                                    value: "Surat, Gujarat",
                                    label: "Surat, Gujarat",
                                  },
                                  {
                                    value: "Indore, Madhya Pradesh",
                                    label: "Indore, Madhya Pradesh",
                                  },
                                  {
                                    value: "Kochi, Kerala",
                                    label: "Kochi, Kerala",
                                  },
                                  {
                                    value: "Ahmedabad, Gujarat",
                                    label: "Ahmedabad, Gujarat",
                                  },
                                  {
                                    value: "New Delhi",
                                    label: "New Delhi",
                                  },
                                  {
                                    value: "Goa",
                                    label: "Goa",
                                  },
                                  {
                                    value: "Pune, Maharashtra",
                                    label: "Pune, Maharashtra",
                                  },
                                  {
                                    value: "Thiruvananthapuram, Kerala",
                                    label: "Thiruvananthapuram, Kerala",
                                  },
                                  {
                                    value: "Coimbatore, Tamil Nadu",
                                    label: "Coimbatore, Tamil Nadu",
                                  },
                                  {
                                    value: "Calicut, Kerala",
                                    label: "Calicut, Kerala",
                                  },
                                  {
                                    value: "Bhubaneswar, Odisha",
                                    label: "Bhubaneswar, Odisha",
                                  },
                                  {
                                    value: "Guwahati, Assam",
                                    label: "Guwahati, Assam",
                                  },
                                  {
                                    value: "Varanasi, Uttar Pradesh",
                                    label: "Varanasi, Uttar Pradesh",
                                  },
                                  {
                                    value: "Hyderabad, Telangana",
                                    label: "Hyderabad, Telangana",
                                  },
                                  {
                                    value: "Tiruchirappalli, Tamil Nadu",
                                    label: "Tiruchirappalli, Tamil Nadu",
                                  },
                                  {
                                    value: "Nagpur, Maharashtra",
                                    label: "Nagpur, Maharashtra",
                                  },
                                  {
                                    value: "Srinagar, Jammu and Kashmir",
                                    label: "Srinagar, Jammu and Kashmir",
                                  },
                                  {
                                    value: "Imphal, Manipur",
                                    label: "Imphal, Manipur",
                                  },
                                  {
                                    value: "Jaipur, Rajasthan",
                                    label: "Jaipur, Rajasthan",
                                  },
                                  {
                                    value: "Madurai, Tamil Nadu",
                                    label: "Madurai, Tamil Nadu",
                                  },
                                  {
                                    value: "Siliguri, West Bengal",
                                    label: "Siliguri, West Bengal",
                                  },
                                  {
                                    value: "Patna, Bihar",
                                    label: "Patna, Bihar",
                                  },
                                  {
                                    value: "Mangalore, Karnataka",
                                    label: "Mangalore, Karnataka",
                                  },
                                  {
                                    value: "Chandigarh",
                                    label: "Chandigarh",
                                  },
                                  {
                                    value: "Singapore",
                                    label: "Singapore",
                                  },
                                  {
                                    value: "Dhaka, Bangladesh",
                                    label: "Dhaka, Bangladesh",
                                  },
                                  {
                                    value: "Colombo, Sri Lanka",
                                    label: "Colombo, Sri Lanka",
                                  },
                                ]}
                              />
                              {/* <Label>Company size </Label> */}
                            </FormGroup>
                          </div>
                          <div style={{ width: "40%", margin: "5%" }}>
                            <FormGroup className="form-label-group position-relative">
                              <Flatpickr
                                className="form-control"
                                style={{ height: 38 }}
                                options={{
                                  dateFormat: "d-m-Y",
                                  minDate: "14-06-2022",
                                  maxDate: "19-06-2022",
                                  // minDate: moment().toDate()
                                }}
                                placeholder="Select Date"
                                // data-enable-time
                                required
                                value={this.state.owndate}
                                onChange={(date) => {
                                  this.setState({ owndate: date });
                                }}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <h7>Return City & Date*</h7>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "40%", margin: "5%" }}>
                            <FormGroup className="form-label-group">
                              <Select
                                required
                                className="React"
                                classNamePrefix="select"
                                value={this.state.depcity}
                                onChange={(a) => {
                                  this.setState({
                                    depcity: a,
                                  });
                                }}
                                placeholder="Return City"
                                options={[
                                  {
                                    value: "Mumbai, Maharashtra",
                                    label: "Mumbai, Maharashtra",
                                  },
                                  {
                                    value: "Bangalore, Karnataka",
                                    label: "Bangalore, Karnataka",
                                  },
                                  {
                                    value: "Chennai, Tamil Nadu",
                                    label: "Chennai, Tamil Nadu",
                                  },
                                  {
                                    value: "Kolkata, West Bengal",
                                    label: "Kolkata, West Bengal",
                                  },
                                  {
                                    value: "Lucknow, Uttar Pradesh",
                                    label: "Lucknow, Uttar Pradesh",
                                  },
                                  {
                                    value: "Amritsar, Punjab",
                                    label: "Amritsar, Punjab",
                                  },
                                  {
                                    value: "Visakhapatnam, Andhra Pradesh",
                                    label: "Visakhapatnam, Andhra Pradesh",
                                  },
                                  {
                                    value: "Kannur, Kerala",
                                    label: "Kannur, Kerala",
                                  },
                                  {
                                    value: "Surat, Gujarat",
                                    label: "Surat, Gujarat",
                                  },
                                  {
                                    value: "Indore, Madhya Pradesh",
                                    label: "Indore, Madhya Pradesh",
                                  },
                                  {
                                    value: "Kochi, Kerala",
                                    label: "Kochi, Kerala",
                                  },
                                  {
                                    value: "Ahmedabad, Gujarat",
                                    label: "Ahmedabad, Gujarat",
                                  },
                                  {
                                    value: "New Delhi",
                                    label: "New Delhi",
                                  },
                                  {
                                    value: "Goa",
                                    label: "Goa",
                                  },
                                  {
                                    value: "Pune, Maharashtra",
                                    label: "Pune, Maharashtra",
                                  },
                                  {
                                    value: "Thiruvananthapuram, Kerala",
                                    label: "Thiruvananthapuram, Kerala",
                                  },
                                  {
                                    value: "Coimbatore, Tamil Nadu",
                                    label: "Coimbatore, Tamil Nadu",
                                  },
                                  {
                                    value: "Calicut, Kerala",
                                    label: "Calicut, Kerala",
                                  },
                                  {
                                    value: "Bhubaneswar, Odisha",
                                    label: "Bhubaneswar, Odisha",
                                  },
                                  {
                                    value: "Guwahati, Assam",
                                    label: "Guwahati, Assam",
                                  },
                                  {
                                    value: "Varanasi, Uttar Pradesh",
                                    label: "Varanasi, Uttar Pradesh",
                                  },
                                  {
                                    value: "Hyderabad, Telangana",
                                    label: "Hyderabad, Telangana",
                                  },
                                  {
                                    value: "Tiruchirappalli, Tamil Nadu",
                                    label: "Tiruchirappalli, Tamil Nadu",
                                  },
                                  {
                                    value: "Nagpur, Maharashtra",
                                    label: "Nagpur, Maharashtra",
                                  },
                                  {
                                    value: "Srinagar, Jammu and Kashmir",
                                    label: "Srinagar, Jammu and Kashmir",
                                  },
                                  {
                                    value: "Imphal, Manipur",
                                    label: "Imphal, Manipur",
                                  },
                                  {
                                    value: "Jaipur, Rajasthan",
                                    label: "Jaipur, Rajasthan",
                                  },
                                  {
                                    value: "Madurai, Tamil Nadu",
                                    label: "Madurai, Tamil Nadu",
                                  },
                                  {
                                    value: "Siliguri, West Bengal",
                                    label: "Siliguri, West Bengal",
                                  },
                                  {
                                    value: "Patna, Bihar",
                                    label: "Patna, Bihar",
                                  },
                                  {
                                    value: "Mangalore, Karnataka",
                                    label: "Mangalore, Karnataka",
                                  },
                                  {
                                    value: "Chandigarh",
                                    label: "Chandigarh",
                                  },
                                  {
                                    value: "Singapore",
                                    label: "Singapore",
                                  },
                                  {
                                    value: "Dhaka, Bangladesh",
                                    label: "Dhaka, Bangladesh",
                                  },
                                  {
                                    value: "Colombo, Sri Lanka",
                                    label: "Colombo, Sri Lanka",
                                  },
                                ]}
                              />
                              {/* <Label>Company size </Label> */}
                            </FormGroup>
                          </div>
                          <div style={{ width: "40%", margin: "5%" }}>
                            <FormGroup className="form-label-group position-relative">
                              <Flatpickr
                                className="form-control"
                                style={{ height: 38 }}
                                options={{
                                  dateFormat: "d-m-Y",
                                  minDate: "14-06-2022",
                                  maxDate: "19-06-2022",
                                  // minDate: moment().toDate()
                                }}
                                placeholder="Select Date"
                                // data-enable-time
                                required
                                value={this.state.depdate}
                                onChange={(date) => {
                                  this.setState({ depdate: date });
                                }}
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <FormGroup className="form-label-group">
                          <Select
                            required
                            className="React"
                            classNamePrefix="select"
                            value={this.state.seatpref}
                            onChange={(a) => {
                              this.setState({
                                seatpref: a,
                              });
                            }}
                            placeholder="Seat preference"
                            options={[
                              {
                                value: "Window",
                                label: "Window",
                              },
                              {
                                value: "Aisle",
                                label: "Aisle",
                              },
                            ]}
                          />
                          {/* <Label>Company size </Label> */}
                        </FormGroup>

                        <FormGroup className="form-label-group">
                          <Select
                            required
                            className="React"
                            classNamePrefix="select"
                            value={this.state.mealpref}
                            onChange={(a) => {
                              this.setState({
                                mealpref: a,
                              });
                            }}
                            placeholder="Meal preference "
                            options={[
                              {
                                value: "Veg",
                                label: "Veg",
                              },
                              {
                                value: "Non Veg ",
                                label: "Non Veg ",
                              },
                            ]}
                          />
                          {/* <Label>Company size </Label> */}
                        </FormGroup>
                        <br />
                      </>
                    ) : null}
                    <h5>Accommodation</h5>
                    <FormGroup className="form-label-group">
                      <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.roomtype}
                        onChange={(a) => {
                          this.setState({
                            roomtype: a,
                          });
                        }}
                        placeholder="Room Type"
                        options={[
                          {
                            value: "Non smoking",
                            label: "Non smoking",
                          },
                          {
                            value: "Smoking",
                            label: "Smoking",
                          },
                        ]}
                      />
                      {/* <Label>Company size </Label> */}
                    </FormGroup>
                        <p>*subject to availability</p>
                    <FormGroup className="form-label-group">
                      <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.accommeal}
                        onChange={(a) => {
                          this.setState({
                            accommeal: a,
                          });
                        }}
                        placeholder="Meal preference "
                        options={[
                          {
                            value: "Vegetarian",
                            label: "Vegetarian",
                          },
                          {
                            value: "Non Vegetarian",
                            label: "Non Vegetarian",
                          },
                          {
                            value: "Jain",
                            label: "Jain",
                          },
                          {
                            value: "Vegan",
                            label: "Vegan",
                          },
                        ]}
                      />
                      {/* <Label>Company size </Label> */}
                    </FormGroup>
                    <FormGroup className="form-label-group">
                      <Input
                        type="text"
                        fontSize={30}
                        placeholder="Food Allergies, if any"
                        // style={{fontSize: 30}}
                        // autoComplete="off"
                        value={this.state.foodalergy}
                        onChange={(e) =>
                          this.setState({ foodalergy: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup className="form-label-group">
                      <Input
                        type="text"
                        // autoComplete="off"
                        placeholder="Other Remarks "
                        value={this.state.otherremarks}
                        onChange={(e) =>
                          this.setState({ otherremarks: e.target.value })
                        }
                      />
                      {/* <Label>Work Email</Label> */}
                    </FormGroup>
                    <br />
                    <h5>Activities</h5>
                    <FormGroup className="form-label-group">
                      <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.golf}
                        onChange={(a) => {
                          this.setState({
                            golf: a,
                          });
                        }}
                        placeholder="Golf"
                        options={[
                          {
                            value: "Certified Golfer",
                            label: "Certified Golfer",
                          },
                          {
                            value: "Participate in Golf Clinic ",
                            label: "Participate in Golf Clinic ",
                          },
                        ]}
                      />
                      {/* <Label>Company size </Label> */}
                    </FormGroup>
                    <h5>* Sunrise Trek in Nandi Hills - 06:30 -07:30hrs</h5>
                    {/* <FormGroup className="form-label-group">
                      <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.trekslot}
                        onChange={(a) => {
                          this.setState({
                            trekslot: a,
                          });
                        }}
                        placeholder="Trekking"
                        options={[
                          {
                            value: "Sunrise Trek – 05:30 – 07:30 ",
                            label: "Sunrise Trek – 05:30 – 07:30 ",
                          },
                          {
                            value: "Normal Trek – 06:30 – 08:30 ",
                            label: "Normal Trek – 06:30 – 08:30 ",
                          },
                        ]}
                      />
                    </FormGroup> */}

                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>T-shirt</h5>
                      <h7
                        onClick={() => {
                          console.log("modal2 opening");
                          this.setState({
                            modal2: true,
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          color: "#cc0000",
                          float: "right",
                        }}
                      >
                        Size Chart
                      </h7>
                    </div>
                    <FormGroup className="form-label-group">
                      <Select
                        required
                        className="React"
                        classNamePrefix="select"
                        value={this.state.tshirt}
                        onChange={(a) => {
                          this.setState({
                            tshirt: a,
                          });
                        }}
                        placeholder="T-shirt Size"
                        options={[
                          {
                            value: "S",
                            label: "S",
                          },
                          {
                            value: "M",
                            label: "M",
                          },
                          {
                            value: "L",
                            label: "L",
                          },
                          {
                            value: "XL",
                            label: "XL",
                          },
                          {
                            value: "XXL",
                            label: "XXL",
                          },
                          {
                            value: "3XL",
                            label: "3XL",
                          },
                        ]}
                      />
                      {/* <Label>Company size </Label> */}
                    </FormGroup>
                    {/* <div style={{ textAlign: "center" }}>
                      <img src="/tshirt.jpg" width={"40%"} />
                    </div> */}
                    <div className={"d-flex justify-content-center"}>
                      <Button
                        className="registerbutton"
                        disabled={this.state.infoloading}
                        // color="warning"
                        type="submit"
                      >
                        {this.state.infoloading ? (
                          <Spinner color="red" />
                        ) : (
                          "Save Info"
                        )}
                      </Button>
                    </div>
                  </>
                </Form>
              </>
            )}
          </ModalBody>
        </Modal>
        <div id="navbar" className="elkevent-nav">
          <nav className="navbar navbar-expand-md navbar-light">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="logo" width={120} />
              </Link>

              <button
                onClick={this.toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("about");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      About
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("whyus");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Why Us
                    </a>
                  </li>
                  {/* <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("speakers");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Speakers
                    </a>
                  </li> */}
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("agenda");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Agenda
                    </a>
                  </li>
                  {/* <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("funfact");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      FunFact
                    </a>
                  </li> */}
                  {/* <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("sponsor");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Sponsor
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("faq");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      FAQ
                    </a>
                  </li>

                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let hero = document.getElementById("register");
                      console.log(hero);
                      e.preventDefault(); // Stop Page Reloading
                      hero &&
                        hero.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      Register
                    </a>
                  </li> */}

                  {/* <li className="nav-item">
                    <NavLink
                      to="/contact"
                      className="nav-link"
                      onClick={this.toggleNavbar}
                    >
                      Contact
                    </NavLink>
                  </li> */}
                </ul>

                <div className="others-option">
                  <ul>
                    <li>
                      <button
                        to="/pricing-1"
                        disabled={this.state.isloading}
                        className="btn btn-primary"
                        onClick={() => {
                          this.setState({
                            isloading: true,
                          });
                          console.log(firebase.auth().currentUser.uid);
                          firebase
                            .database()
                            .ref("pub_user/" + firebase.auth().currentUser.uid)
                            .once("value", (snap) => {
                              console.log(snap.val());
                              if (snap.val().form) {
                                this.setState({
                                  isloading: false,
                                  modal: true,
                                  form: true,
                                });
                              } else {
                                console.log("Form not submitted");
                                this.setState({
                                  isloading: false,
                                  modal: true,
                                  form: false,
                                  category: snap.val().category,
                                });
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });

                          // firebase.auth().signOut();
                        }}
                      >
                        {this.state.isloading ? (
                          <Spinner color="red" />
                        ) : (
                          "BOOK YOUR SERVICE"
                        )}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default withRouter(Navigation);
